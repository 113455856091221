import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { HttpClient } from '@angular/common/http';
import { FcmService } from './services/fcm/fcm.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import {SessionInfoServiceService} from "./services/session-info-service/session-info-service.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  lockedState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private initPlugin: boolean;
  private sessionInfo;
  constructor(
    private platform: Platform,
    private deeplinks: Deeplinks,
    private router: Router,
    private zone: NgZone,
    private http: HttpClient,
    private fcmService: FcmService,
    private sessionInfoService: SessionInfoServiceService,
  ) {
    console.log('app initializeApp:---->>>>');
    this.initializeApp();
  }
  async ngOnInit() {
    // this.sessionInfo = await this.sessionInfoService.getSessionInfo();

    await this.fcmService.registerPush();
    // this.sessionInfo = await this.sessionInfoService.getSessionInfo();
    // this.fcmService.sendTokenToServer(this.sessionInfo['id']);

    window.addEventListener('navigate', async (event: CustomEvent) => {
      const data = event.detail; // 获取 detail 数据
      console.log('navigate event11111:', data);
      console.log('navigate event11111-page:', data.page);

      if (data.page === 'product') {
        await this.router.navigate(['/product', data.productId], {
          state: { parent_url: '/homepage-new' },
          queryParams: { id: data.productId }
        });
      }
      if (data.page === 'chat') {
        if (data.sender === 'customer') {
          await this.router.navigate(['/seller/chatroom']);
        } else {
          await this.router.navigate(['/chatroom']);
        }
      }
      if (data.page === 'order') {
        console.log('Navigating to chatroom because page is order');
        if (data.sender === 'customer') {
          await this.router.navigate(['/seller/order']);
        }else {
          await this.router.navigate(['/my-order']);
        }
      }
      if(data.page === 'notifications'){
        await this.router.navigate(['/notifications']);
      }
      if(data.page === 'withdrawal'){
        await this.router.navigate(['/seller/profile']);
      }
    });
  }
  initializeApp() {
    // console.log('app comment initializeApp2222:---->>>>');
    this.platform.ready().then(() => {
      console.log('Hello Fishnet App is ready');
      this.fcmService.initPush();

      this.setupDeepLinks();
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split('.com.hk/').pop();
        const parts = slug.split('?');
        const route = parts[0];
        const params = parts[1];
        const paramPairs = params.split('&');
        const args = {};

        paramPairs.forEach(pair => {
          const [param, value] = pair.split('=');
          args[param] = value;
        });
        switch (route) {
          case 'forum':
            this.router.navigate(['/forum'], { queryParams: { post_id: args['post_id'] } });
            break;
          case 'product':
            this.router.navigate(['/product', args['productId']], { queryParams: { id: args['productId'] }, state: { parent_url: '/homepage-new' } });
            console.log('-----------ios.productId-------------', args['productId']);
            break;
          case 'shop':
            this.router.navigate(['/buyer/shop-homepage'], { queryParams: { id: args['shopId'], userId: args['userId']} });
            console.log('-----------ios.shopId-------------', args['shopId'], args['userId']);
            break;
          default:
            console.error('Unknown deeplink route:', route);
        }
        return;
      });
    });
  }

  setupDeepLinks() {
    this.deeplinks.route({
      '/forum': 'buyerForum',
      '/product': 'productDetails',
      '/shop': 'shopDetails'
    }).subscribe(match => {
      const route = match.$route;
      const args = match.$args;

      switch (route) {
        case 'buyerForum':
          this.router.navigate(['/forum'], { queryParams: { post_id: args.post_id } });
          break;
        case 'sellerForum':
          this.router.navigate(['/seller/forum', args.id]);
          break;
        case 'productDetails':
          this.router.navigate(['/product', args.productId], { queryParams: { id: args.productId }, state: { parent_url: '/homepage-new' } });
          console.log('-----------args.productId-------------', args.productId);
          break;
        case 'shopDetails':
          this.router.navigate(['/buyer/shop-homepage'], { queryParams: { id: args.shopId, userId: args.userId} });
          console.log('-----------args.shopId-------------', args.shopId, args.userId);
          break;
        default:
          console.error('Unknown deeplink route:', route);
      }
      return;
    }, nomatch => {
      console.error('Got a deeplink that didn\'t match', nomatch);
      return;
    });
  }
}
