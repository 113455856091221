import { Component, OnInit, Input, NgModule, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from 'src/appSettings';
import { Share } from '@capacitor/share';
import domtoimage from 'dom-to-image';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import {StorageService} from '../../../services/storage-service/storage.service';
import { SessionInfoServiceService } from 'src/app/services/session-info-service/session-info-service.service';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { ImageModalComponent } from 'src/app/modals/image-modal/image-modal.component';
// import { PostCommentCardComponent } from '../post-comment-card/post-comment-card.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss'],
})
export class PostCardComponent implements OnInit {


  @Input() post_info;
  private API_ENDPOINT=AppSettings.API_ENDPOINT;
  private POST_ENDPOINT="post-info/";
  private COMMENT_ENDPOINT="comment-info/";
  public comments=[];
  public displayedComments=[];
  public initialCommentsToShow = 3;
  private comment_count=0;
  public showAllComments=false;
  public task: string;
  public post_id;
  post_liked = false;
  sessionInfo: any;
  @ViewChild('commentInput') commentInput: ElementRef;


  // public imageTypes = ['jpeg',  'png', 'gif', 'tiff', 'psd', 'eps', 'raw', 'heic', 'webp','jpg'];
  public imageTypes = [
    'jpeg',    // JPEG (Joint Photographic Experts Group)
    'jpg',     // JPEG (Joint Photographic Experts Group)
    'jfif',    // JPEG File Interchange Format
    'png',     // Portable Network Graphics
    'gif',     // Graphics Interchange Format
    'tiff',    // Tagged Image File Format
    'tif',     // Tagged Image File Format
    'bmp',     // Bitmap Image File
    'webp',    // Web Picture format
    'heic',    // High Efficiency Image Container
    'heif',    // High Efficiency Image Format
    'svg',     // Scalable Vector Graphics
    'raw',     // Raw Image Formats (e.g., NEF, CR2)
    'ico',     // Icon Image File
    'eps',     // Encapsulated PostScript
    'pdn',     // Paint.NET Image
    'psd',     // Adobe Photoshop Document
    'exr',     // OpenEXR image file
    'avif',    // AV1 Image File Format
    'indd',    // Adobe InDesign Document
    'jpe',     // JPEG (Joint Photographic Experts Group)
    'jpg',     // JPEG (Joint Photographic Experts Group)
    'pct',     // PICT (Apple Macintosh)
    'tga',     // Targa Image File
    'pcx',     // PC Paintbrush Bitmap Image
    'xpm',     // X11 Pixmap Image
    'PNG',
  ];

  public videoTypes = ['webm', 'mpg', 'mp2', 'mp4', 'mpeg', 'mpe', 'mpv', 'ogg', 'mp4', 'avi', 'wmv', 'mov', 'flv'];

  constructor(
    private http: HttpClient,
    private ss: StorageService,
    private sessionInfoService: SessionInfoServiceService,
    private modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    private router: Router
  ) { }

  ngOnInit() {
    this.sessionInfoService.getSessionInfo().then((session_info) => {
      this.sessionInfo = session_info;
      this.post_id = this.post_info["id"];
      this.getComments();
      this.post_liked = this.post_info.users_like.some(el => el.user_id === this.sessionInfo["id"]);
    });
    this.updateDisplayedComments();
  }

  async getComments(){
    await this.http.get(this.API_ENDPOINT + this.COMMENT_ENDPOINT + "get-post-comments/"+this.post_info["id"]).subscribe(
      data=>{
        this.comments=data["data"];
        this.comment_count=this.comments.length;
        this.updateDisplayedComments();
      },
      error=>{
        console.log(error);
      }
    );
  }

  showAllClicked(){
    this.showAllComments= !this.showAllComments;
    this.updateDisplayedComments();
  }

  async likePost(){
    let headers = new HttpHeaders();
    let postData = {
      post_id: this.post_info["id"],
      user_id: this.sessionInfo["id"]
    };
    await this.http.post(this.API_ENDPOINT + this.POST_ENDPOINT + 'like-post/', JSON.stringify(postData)).subscribe(
      data=>{
        if (data["status"]==200){
          console.log(data);
          this.post_liked = data["data"]["you_liked"];
          this.post_info["likes"] = (parseInt(data["data"]["likes"])).toString();
        }
      },
      error=>{
        console.log(error);
      });
  }

  postComment() {
    if (this.task && this.task.trim()) {
      const newComment = {
        content: this.task,
        datetime: new Date().toISOString(),
        display_name: '当前用户',
        avatar_url: '默认头像URL',
      };

      this.comments.unshift(newComment);
      
      this.updateDisplayedComments();
      
      this.task = '';
      
      this.commentInput.nativeElement.setFocus();
    }
  }

  toggleComments() {
    this.showAllComments = !this.showAllComments;
    this.updateDisplayedComments();
  }

  updateDisplayedComments() {
    if (this.showAllComments) {
      this.displayedComments = this.comments;
    } else {
      this.displayedComments = this.comments.slice(0, this.initialCommentsToShow);
    }
  }

  formatCommentTime(datetime: string): string {
    const commentDate = new Date(datetime);
    const now = new Date();
    const diffInMinutes = Math.floor((now.getTime() - commentDate.getTime()) / (1000 * 60));

    if (diffInMinutes < 1) {
      return '刚刚';
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes}分钟前`;
    } else if (diffInMinutes < 1440) {
      return `${Math.floor(diffInMinutes / 60)}小时前`;
    } else {
      return commentDate.toLocaleDateString();
    }
  }

  async shareImage() {
    const deepLink = `https://fishnet.com.hk/forum?post_id=` + this.post_info.id;
    Share.share({
      title: this.post_info['display_name'],
      text: deepLink,
      // url: encodedDeepLink,
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing ::: ', error));
  }

  // async showMedia(link) {
  //   window.open(link);
  // }

  async showMedia(imageUrl: string) {
    const modal = await this.modalController.create({
      component: ImageModalComponent,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: {
        imageUrl: imageUrl
      },
    });

    return await modal.present();
  }

  checkMediaTypeFromLink(link) {
    const type = link.split('.')[link.split('.').length - 1];
    if (this.imageTypes.includes(type)) {
      return true;
    } else if (this.videoTypes.includes(type)) {
      return false;
    }
  }
}
