import { Component, OnInit , OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {AppSettings} from "../../../appSettings";
import { log } from 'console';
import {SessionInfoServiceService} from "../../services/session-info-service/session-info-service.service";
import {UpdateUnreadChatService} from "../../services/chat/update-unread-chat.service";
import {Subscription, timer} from 'rxjs';
import {AddToCartInfoService} from "../../services/add-to-cart-info/add-to-cart-info.service";
@Component({
  selector: 'app-toolbar-buyer',
  templateUrl: './toolbar-buyer.component.html',
  styleUrls: ['./toolbar-buyer.component.scss'],
})
export class ToolbarBuyerComponent implements OnInit {
  private api_url = AppSettings.API_ENDPOINT;
  private merchant_id;
  private customer_id;
  sessionInfo: any;
  count : any;
  private refreshSubscription: Subscription;


  showTimer = false;
  formattedTime = '15:00';
  private timerSubscription: Subscription;
  private showTimerSubscription: Subscription;

  constructor(
    private router: Router,
    private http: HttpClient,
    private sessionInfoService: SessionInfoServiceService,
    private toolbarService: UpdateUnreadChatService,
    private addToCartInfoService: AddToCartInfoService,
  ) { }

  async ngOnInit() {
    this.sessionInfo = await this.sessionInfoService.getSessionInfo();
    if (this.sessionInfo) {
      this.customer_id = this.sessionInfo["id"];
    } else {
      console.error('Session info is undefined.');
    }
    this.receiveInformation();

    this.refreshSubscription = this.toolbarService.getRefreshObserver().subscribe(() => {
      this.receiveInformation();
    });

    this.timerSubscription = this.addToCartInfoService.getTimerObservable().subscribe(time => {
      this.formattedTime = time;
      this.showTimer = this.addToCartInfoService.showTimer;
    });
    this.showTimerSubscription = this.addToCartInfoService.showTimer$.subscribe(show => {
      this.showTimer = show;
    });
  }



  receiveInformation(){
    let requestPayload = {
      customer_user_id: this.customer_id,
      // merchant_id: this.merchant_id,
      sender: "merchant",

    };
    this.http.post(this.api_url+'chat-info/get-receive-information', JSON.stringify(requestPayload)).subscribe(
      data=> {
        console.log('receiveInformation',data);
        this.count = data["data"];
        console.log('this.count1',this.count);
      },
        err=>{
          console.log(err);
          // this.loaderService.closeLoader();
          // this.errorAlert.presentAlert("Couldn't send message")
        }
    );
  }



  ngOnDestroy() {

    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    if (this.showTimerSubscription) {
      this.showTimerSubscription.unsubscribe();
    }

  }

  async openHomepage(){
    //this.router.navigate(['/homepage-new'])
    await this.router.navigate(['/homepage-new'], {replaceUrl: true});
  }

  async openForum(){
    //this.router.navigate(['./forum'])
    await this.router.navigate(['/forum'], {replaceUrl: true});
  }

  async openChatroom(){
    //this.router.navigate(['/chatroom'])
    await this.router.navigate(['/chatroom'], {replaceUrl: true});
  }

  async openShoppingCart(){
    //this.router.navigate(['/shopping-cart'])
    await this.router.navigate(['/shopping-cart'], {replaceUrl: true});
  }

  async openProfile(){
    //this.router.navigate(['/profile'])
    await this.router.navigate(['/profile'], {replaceUrl: true});
  }

}
