import { Injectable } from '@angular/core';
import { StorageService } from '../storage-service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionInfoServiceService {

  session_info = {};

  constructor(
    private storageService: StorageService
  ) { }

  setSessionInfo(data){
    this.session_info = data;


  }

  getSessionInfo() {
    const isSessionInfoEmpty = Object.keys(this.session_info).length === 0;

    if (isSessionInfoEmpty) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const session_info = this.getSessionInfoFromStorage();
          resolve(session_info);
        }, 500);
      });
    } else {

      return Promise.resolve(this.session_info);
    }
  }



  async getSessionInfoFromStorage(){
    let data = await this.storageService.get('user_data');
    let session_info = JSON.parse(data);
    return session_info;
  }

  clearSessionInfo(){
    this.session_info = {};


  }

}
