import { Injectable } from '@angular/core';
import {AlertController, LoadingController, ModalController} from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class LoaderServiceService {

  constructor(
    public loadingCtrl: LoadingController,
    public alertController: AlertController
  ) { }

  // Loader and Spinner
  async basicLoader(message) {
    const loading = await this.loadingCtrl.create({
        spinner: 'crescent',
        message: message,
        // duration: 3000,
        backdropDismiss: false,
        translucent: true,
        // cssClass:'ion-loading-class'
    })
    return loading.present();
  }

  async basicLoading(message: string) {
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: message,
      backdropDismiss: false,
      translucent: true,
    });
    await loading.present();
    return loading;
  }


  closeLoader() {
    this.loadingCtrl.dismiss().then((res) => {
        // console.log('Loader hidden', res);
    }).catch((error) => {
        console.log(error);
    });
  }

  async showPopup(header: string, message: string) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ['確定'],
    });
    await alert.present();
  }



}
