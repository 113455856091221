import {HttpClient} from '@angular/common/http';
import {Component, OnInit, Input} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {ProductCreateService} from 'src/app/services/product-create/product-create.service';
import {ProductInfoService} from 'src/app/services/product-info/product-info.service';
import {AppSettings} from 'src/appSettings';
import {SessionInfoServiceService} from "../../services/session-info-service/session-info-service.service";


@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() product_info = {};
  @Input() parent_url;
  @Input() urlType = null;
  imageTypes = [
    'jpeg',    // JPEG (Joint Photographic Experts Group)
    'jpg',     // JPEG (Joint Photographic Experts Group)
    'jfif',    // JPEG File Interchange Format
    'png',     // Portable Network Graphics
    'gif',     // Graphics Interchange Format
    'tiff',    // Tagged Image File Format
    'tif',     // Tagged Image File Format
    'bmp',     // Bitmap Image File
    'webp',    // Web Picture format
    'heic',    // High Efficiency Image Container
    'heif',    // High Efficiency Image Format
    'svg',     // Scalable Vector Graphics
    'raw',     // Raw Image Formats (e.g., NEF, CR2)
    'ico',     // Icon Image File
    'eps',     // Encapsulated PostScript
    'pdn',     // Paint.NET Image
    'psd',     // Adobe Photoshop Document
    'exr',     // OpenEXR image file
    'avif',    // AV1 Image File Format
    'indd',    // Adobe InDesign Document
    'jpe',     // JPEG (Joint Photographic Experts Group)
    'jpg',     // JPEG (Joint Photographic Experts Group)
    'pct',     // PICT (Apple Macintosh)
    'tga',     // Targa Image File
    'pcx',     // PC Paintbrush Bitmap Image
    'xpm',     // X11 Pixmap Image
    'PNG',
  ];
  // imageTypes = ['jpeg',  'png', 'gif', 'tiff', 'psd', 'eps', 'raw', 'heic', 'webp','jpg','PNG'];

  videoTypes = ['webm', 'mpg', 'mp2', 'mp4', 'mpeg', 'mpe', 'mpv', 'ogg', 'mp4', 'avi', 'wmv', 'mov', 'flv', 'quicktime'];
  firstScreenType: any;
  combinedHashtags: string = '';
  private discounted_price;
  private image_url;
  private product_id;
  private current_url;
  private url_type;
  private user_id;
  private parentUrlType = 1;
  private sessionInfo;
  public hashtag_array = [];

  public api_url = AppSettings.API_ENDPOINT;

  public isSellerProductOutOfStock: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productInfoService: ProductInfoService,
    private http: HttpClient,
    private productCreateService: ProductCreateService,
    private sessionInfoService: SessionInfoServiceService,
  ) {
  }


  async ngOnInit() {
    this.sessionInfo = await this.sessionInfoService.getSessionInfo();
    this.user_id = this.sessionInfo['id'];
    await this.getParentUrlType();
    this.getAllHashtag();
    this.discounted_price = Math.round(((100 - this.product_info["discount"]) * 0.01 * this.product_info["price"]) * 10) / 10;
    this.product_info['discounted_price'] = this.discounted_price;
    // console.log(this.discounted_price);
    const firstImage = this.product_info['media'].find(el => this.checkMediaTypeFromLink(el) === 'image');
    if (firstImage) {
      this.checkAssetType(firstImage);
    }
    this.current_url = this.router.url;

    if (this.product_info['stock'] === '0' && this.parent_url.includes('seller')) {
      this.isSellerProductOutOfStock = true;

    }
    this.url_type = this.urlType;


    // Future parent url will be the current url
    // this.parent_url = this.router.url;
  }

//
  async getParentUrlType() {
    if (this.parent_url === '/buyer/shop-homepage' || this.parent_url === '/seller/homepage') {
      this.parentUrlType = 0;
    } else {
      this.parentUrlType = 1;
    }
  }

  async getAllHashtag() {
    const payload = {
      productId: this.product_info["id"]
    };

    try {
      const response = await this.http.post(this.api_url + 'product-info/all-hashtag-by-product-id', JSON.stringify(payload)).toPromise();
      this.hashtag_array = response["data"];
      this.combineHashtags();
    } catch (error) {
      console.log(error);
    }
  }

  async openProductPage() {
    console.log("Image Url is", this.image_url);
    console.log("this.product_info-===", this.product_info);
    this.product_id = this.product_info["id"];
    this.productInfoService.setProductInfo(this.product_info);
    if (this.parent_url.includes('seller')) {
      await this.router.navigate(['/seller/product', this.product_id], {state: {parent_url: this.parent_url}});
    } else {
      this.registerProductClick(this.product_id);
      await this.router.navigate(['/product', this.product_id], {
        state: {
          parent_url: this.parent_url,
          url_type: this.url_type
        }
      });
    }
  }

  registerProductClick(product_id) {
    this.productCreateService.updateProductClick(product_id);
  }

  async checkAssetType(url) {
    if (typeof (url) === 'string') {
      const type = url.split('.')[url.split('.').length - 1];
      if (this.imageTypes.includes(type)) {
        this.firstScreenType = 'image';
        this.image_url = url;
      } else if (this.videoTypes.includes(type)) {
        this.firstScreenType = 'video';
        this.image_url = url;
      }

    } else {
      // console.log('ERROR');
    }

  }

  checkMediaTypeFromLink(link) {
    try {
      const type = link.split('.')[link.split('.').length - 1];
      if (this.imageTypes.includes(type)) {
        return 'image';
      } else if (this.videoTypes.includes(type)) {
        return 'video';
      }
    } catch (e) {
      console.log(e);
    }
    return 'nothing';
  }

  truncateString(str: string, maxLength: number): string {
    if (!str || str.length <= maxLength) {
      return str;
    }
    return str.substring(0, maxLength) + '...';
  }

  combineHashtags() {
    this.combinedHashtags = this.hashtag_array.map((hashtag, index) => {
      const nextHashtag = this.hashtag_array[index + 1];
      return nextHashtag ? `${hashtag.hashtag} / ${nextHashtag.hashtag}` : null;
    }).join('');
  }


  async openStore() {
    console.log("openStore.url--->>>", this.router.url);
    console.log("this.user_id--->>>", this.user_id);
    console.log("this.product_info['merchant_id']--", this.product_info['merchant_id']);

    this.router.navigate(['buyer/shop-homepage'], {
        state: {
          previous_url: this.router.url,
          merchant_id: this.product_info['merchant_id'],
          user_id: this.user_id
        }
      }
    );

    // this.registerStoreClick(this.shop_info['id']);
  }

  registerStoreClick(merchant_id) {
    this.productCreateService.updateStoreClick(merchant_id);
  }


}

