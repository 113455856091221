import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent {
  @Input() imageUrl: string;


  // public imageTypes = ['jpeg',  'png', 'gif', 'tiff', 'psd', 'eps', 'raw', 'heic', 'webp','jpg'];
  public imageTypes = [
    'jpeg',    // JPEG (Joint Photographic Experts Group)
    'jpg',     // JPEG (Joint Photographic Experts Group)
    'jfif',    // JPEG File Interchange Format
    'png',     // Portable Network Graphics
    'gif',     // Graphics Interchange Format
    'tiff',    // Tagged Image File Format
    'tif',     // Tagged Image File Format
    'bmp',     // Bitmap Image File
    'webp',    // Web Picture format
    'heic',    // High Efficiency Image Container
    'heif',    // High Efficiency Image Format
    'svg',     // Scalable Vector Graphics
    'raw',     // Raw Image Formats (e.g., NEF, CR2)
    'ico',     // Icon Image File
    'eps',     // Encapsulated PostScript
    'pdn',     // Paint.NET Image
    'psd',     // Adobe Photoshop Document
    'exr',     // OpenEXR image file
    'avif',    // AV1 Image File Format
    'indd',    // Adobe InDesign Document
    'jpe',     // JPEG (Joint Photographic Experts Group)
    'jpg',     // JPEG (Joint Photographic Experts Group)
    'pct',     // PICT (Apple Macintosh)
    'tga',     // Targa Image File
    'pcx',     // PC Paintbrush Bitmap Image
    'xpm',     // X11 Pixmap Image
    'PNG',
  ];

  public videoTypes = ['webm', 'mpg', 'mp2', 'mp4', 'mpeg', 'mpe', 'mpv', 'ogg', 'mp4', 'avi', 'wmv', 'mov', 'flv'];

  constructor(
    private modalController: ModalController,
    private sanitizer: DomSanitizer
  ) {}

  checkMediaTypeFromLink(link) {
    const type = link.split('.')[link.split('.').length - 1];
    if (this.imageTypes.includes(type)) {
      return 'image';
    } else if (this.videoTypes.includes(type)) {
      return 'video';
    } else {
      return 'doc';
    }
  }

  getSafeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async close() {
    const closeModal: string = "Modal Closed";
    await this.modalController.dismiss(closeModal);
  }
}
