import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StorageService } from './services/storage-service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private storageService: StorageService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      const data = await this.storageService.get('user_data');
      if (data) {
        const userData = JSON.parse(data);
        if (userData.session_id) {
          return true;
        }
      }
      
      // 未登录时，如果不是访问visitor或signin页面，则重定向到visitor
      if (state.url !== '/visitor' && state.url !== '/signin') {
        this.router.navigate(['/visitor']);
        return false;
      }
      
      return true;
    } catch (err) {
      console.error('Auth guard error:', err);
      if (state.url !== '/visitor' && state.url !== '/signin') {
        this.router.navigate(['/visitor']);
        return false;
      }
      return true;
    }
  }
}