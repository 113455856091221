import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AddToCartInfoService {

  constructor() { this.startTimer(); }

  private addToCartInfo = [];

  private paymentCartInfo = [];

  private finalPaymentPrice;

  private shoppingCartInfo: any[] = [];
  private timerSubscription: any;
  private timeLeft: number = 900;
  private startTime: number; // 记录开始时间戳
  public showTimer: boolean = false;
  public formattedTime: string = '15:00';
  private timerSubject = new Subject<string>();

  private showTimerSubject = new Subject<boolean>();
  public showTimer$ = this.showTimerSubject.asObservable();


  addToCart(product) {
    this.shoppingCartInfo.push(product);
    if (product.is_organism === '1') {
      this.showTimer = true;
      this.showTimerSubject.next(true);
      this.resetTimer();
    } else {
      this.checkOrganismInCart();
    }
    this.notifyTimer();
  }


  private checkOrganismInCart() {
    this.showTimer = this.shoppingCartInfo.some(item => item.is_organism === '1');
    this.showTimerSubject.next(this.showTimer);
  }


  private resetTimer() {
    this.startTime = Date.now(); // 记录当前时间
    this.timeLeft = 60; // 设置初始倒计时时间（秒）
    this.updateFormattedTime();
    this.stopTimer();
    this.startTimer();
  }

  private startTimer() {
    this.timerSubscription = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - this.startTime) / 1000); // 计算经过的时间（秒）
      this.timeLeft = 900 - elapsedTime; // 更新剩余时间
      this.updateFormattedTime();

      if (this.timeLeft <= 0) {
        this.timeLeft = 0;
        this.showTimer = false;
        this.showTimerSubject.next(false);
        this.stopTimer();
        this.clearOrganismProducts();
      }
      this.notifyTimer();
    }, 1000);
  }



  private stopTimer() {
    if (this.timerSubscription) {
      clearInterval(this.timerSubscription);
    }
  }
  paymentSuccessfulToStopTimer() {
    this.stopTimer();
    this.showTimer = false;
    this.showTimerSubject.next(false);
  }
  private clearOrganismProducts() {
    this.shoppingCartInfo = this.shoppingCartInfo.filter(item => item.is_organism !== '1');
    this.addToCartInfo = this.addToCartInfo.filter(item => item.is_organism !== '1');
  }

  private updateFormattedTime() {
    const minutes = Math.floor(this.timeLeft / 60);
    const seconds = this.timeLeft % 60;
    this.formattedTime = `${this.pad(minutes)}:${this.pad(seconds)}`;
  }


  private pad(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }


  private notifyTimer() {
    this.timerSubject.next(this.formattedTime);
  }


  getTimerObservable() {
    return this.timerSubject.asObservable();
  }


  getShoppingCartInfo() {
    return this.shoppingCartInfo;
  }



  setFinalPaymentPrice(data){
    this.finalPaymentPrice = data;
  }

  getFinalPaymentPrice(){
    return this.finalPaymentPrice;
  }

  setPaymentCartItems(data){
    this.addToCartInfo = data;
  }

  getPaymentCartItems(){
    return this.addToCartInfo;
  }

  setAddToCartInfo(data){
    this.addToCartInfo.push({...data, add_to_cart_time: new Date()});
  }
  updateAddToCartInfo(data){
    this.addToCartInfo=(data);
    const hasOrganismItem = this.addToCartInfo.some(item =>
      item.product && item.product.some(product => product.is_organism === '1')
    );
    if (!hasOrganismItem) {
      this.stopTimer();
      this.showTimer = false;
      this.showTimerSubject.next(false);
    } else {
      this.showTimerSubject.next(true);
    }
  }

  getAddtoCartInfo(){
    return this.addToCartInfo;
  }

  clearCartInfo(){
    console.log("clear Cart Info");
    this.addToCartInfo = [];
    this.paymentCartInfo = [];
    this.finalPaymentPrice = 0;

    console.log(this.addToCartInfo, this.paymentCartInfo);
  }

  clearCartInfoByItem(paymentSuccessfulItem) {
    console.log("clear Cart Info by item");
    console.log("this.addToCartInfo--", this.addToCartInfo);
    console.log("this.paymentCartInfo--", this.paymentCartInfo);
    console.log("paymentSuccessfulItem", paymentSuccessfulItem);

    this.addToCartInfo = this.addToCartInfo.filter(cartItem =>
      !this.paymentCartInfo.some(paymentItem =>
        paymentItem.id === cartItem.id
      )
    );

    console.log("Updated this.addToCartInfo--", this.addToCartInfo);
  }




}
